import React, { useCallback } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

const WEB3FORMS_API_KEY = `2f03ecd2-dca3-411c-95c2-1151938a5c47`;

const FieldError = ({ error }) =>
  error ? (
    <span className="form__error" role="alert">
      {error.message}
    </span>
  ) : null;

const FormContatti = ({ className }) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = useCallback(
    async (e) => {
      clearErrors('submitError');
      handleSubmit(async (values) => {
        console.log('values: ', values);
        const data = new FormData();
        for (const [key, value] of Object.entries(values)) {
          data.append(key, value);
        }
        data.append('replyTo', values.email);
        data.append('apikey', WEB3FORMS_API_KEY);
        try {
          const response = await fetch(`https://api.web3forms.com/submit`, {
            headers: { Accept: 'application/json' },
            method: 'POST',
            body: data
          });
          console.log('response: ', response);
          if (response.ok) {
            reset();
            return response.json();
          } else {
            throw new Error(`Something went wrong ${response.status} ${response.statusText}`);
          }
        } catch (err) {
          console.error('Submit error: ', err);
          setError('submitError', {
            type: 'form',
            message: 'Si è verificato un errore, riprova più tardi'
          });
        }
      })(e);
    },
    [reset, setError, clearErrors, handleSubmit]
  );

  return (
    <div className={className}>
      <div className="row">
        <div className="col-lg-8">
          {isSubmitSuccessful ? (
            <>
              <div className="row">
                <div className="col-12">
                  <h2>Grazie per avermi contattato</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <StaticImage
                    src="../cms/media/loffre.jpg"
                    style={{ maxWidth: '200px' }}
                    alt="Francesco Loffredo"
                  />
                </div>
                <div className="col-lg-4 mt-4 mt-lg-0 d-lg-inline-flex align-items-center">
                  <p className="form__success">Ti risponderò appena mi sarà possibile.</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <form className="form" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12 mb-3">
                    <h2>Scrivimi per un appuntamento o per informazioni</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="form-name" className="form__label">
                      Nome e cognome*
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      id="form-name"
                      type="text"
                      aria-invalid={errors.name ? 'true' : 'false'}
                      {...register('name', {
                        required: 'Inserisci nome e cognome'
                      })}
                      maxLength={50}
                      className={classNames('form__field', {
                        'form__field--filled': watch('name')
                      })}
                    />
                    <FieldError error={errors.name} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="form-subject" className="form__label">
                      Oggetto*
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <select
                      id="form-subject"
                      type="text"
                      defaultValue=""
                      aria-invalid={errors.subject ? 'true' : 'false'}
                      {...register('subject', {
                        required: 'Seleziona una opzione'
                      })}
                      className={classNames('form__field', 'form-field--select', {
                        'form__field--filled': watch('subject')
                      })}
                    >
                      <option value="" disabled>
                        Seleziona una sede o informazioni
                      </option>
                      <option value="Visita in sede a pavia">Visita in sede a Pavia</option>
                      <option value="Visita in sede ad Alessandria">
                        Visita in sede ad Alessandria
                      </option>
                      {/* <option value="Visita in sede a Voghera">Visita in sede a Voghera</option> */}
                      <option value="Colloquio online">Colloquio online</option>
                      <option value="Informazioni">Informazioni</option>
                    </select>
                    <FieldError error={errors.subject} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="form-email" className="form__label">
                      E-mail*
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      id="form-email"
                      type="email"
                      aria-invalid={errors.email ? 'true' : 'false'}
                      {...register('email', {
                        required: 'Inserisci un indirizzo email valido'
                      })}
                      maxLength={50}
                      className={classNames('form__field', {
                        'form__field--filled': watch('email')
                      })}
                    />
                    <FieldError error={errors.email} />
                  </div>
                </div>
                <div className="row extra">
                  <div className="col-lg-4">
                    <label htmlFor="form-gotcha" className="form__label">
                      Lasciare vuoto
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      id="form-gotcha"
                      type="text"
                      {...register('botcheck')}
                      autoComplete="off"
                      tabIndex={-1}
                      aria-hidden={true}
                      className="form__field"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="form-message" className="form__label">
                      Messaggio*
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <textarea
                      id="form-message"
                      type="text"
                      aria-invalid={errors.message ? 'true' : 'false'}
                      {...register('message', { required: 'Scrivi un breve messaggio' })}
                      autoComplete="off"
                      maxLength={400}
                      className={classNames('form__field form__field--text', {
                        'form__field--filled': watch('message')
                      })}
                    ></textarea>
                    <FieldError error={errors.message} />
                  </div>
                </div>
                {errors.submitError && (
                  <div className="row">
                    <div className="col-12">
                      <h2 className="form__submit-error mt-4" role="alert">
                        {errors.submitError.message}
                      </h2>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-6">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="form__btn justify-content-center d-inline-flex align-items-center"
                    >
                      {isSubmitting && <span className="spinner my-0 me-2"></span>}
                      Invia
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
        <div className="col-lg-4 mt-4 mt-lg-0">
          <div className="row">
            <div className="col-12 mb-3">
              <h2>Le mie recensioni su MioDottore</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8 col-md-6 col-lg-12">
              <a
                id="zl-url"
                className="zl-url"
                href="https://www.miodottore.it/francesco-loffredo-2/psicologo-clinico-psicoterapeuta/voghera"
                rel="nofollow"
                data-zlw-doctor="francesco-loffredo-2"
                data-zlw-type="big"
                data-zlw-opinion="true"
                data-zlw-hide-branding="true"
              >
                Francesco Loffredo - MioDottore.it
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormContatti;
