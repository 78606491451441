import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import MapContainer from '../components/map';
import FormContatti from '../components/form-contatti';
import Breadcrumb from '../components/breadcrumb';

const ContattiPage = ({ data }) => {
  const breadcrumb = [
    {
      name: 'Contatti'
    }
  ];
  return (
    <Layout data={data}>
      <Seo title="Contatti" data={data} />
      <div className="section mt-0">
        <div className="container-xl">
          <div className="row mb-3">
            <div className="col-12">
              <Breadcrumb breadcrumb={breadcrumb} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h1>Contatti</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <p>
                <strong>Studio VivaMente</strong>
                <br />
                Via Milazzo 85, Pavia
              </p>
              <MapContainer address="Via Milazzo 85 Pavia+Studio" />
            </div>
            <div className="col-12 mt-4 mt-md-0 col-md-6">
              <p>
                <strong>Studi 360° - Psicoterapia Pedagogia Formazione</strong>
                <br />
                Viale Medaglie d'Oro 30, Alessandria
              </p>
              <MapContainer address="360° Psicoterapia Pedagogia Formazione, Via Medaglie D'Oro, Alessandria, AL, Italia" />
            </div>
            {/* <div className="col-12 mt-4 mt-md-5 col-md-6">
              <p>
                <strong>Studio privato Voghera</strong>
                <br />
                Via Lamarmora, 9, 27058 Voghera
              </p>
              <MapContainer address="Dott. Francesco Loffredo, Psicologo clinico Via Lamarmora, 9, 27058 Voghera" />
            </div> */}
          </div>
          <FormContatti className="py-4 mt-5" />
        </div>
      </div>
    </Layout>
  );
};

export default ContattiPage;
export const query = graphql`
  query {
    ...HeaderFragment
    ...SeoFragment
  }
`;
