import React from 'react';

const MapContainer = ({ address }) => (
  <div className="google_map">
    <div className="gmap_canvas">
      <iframe
        title="Mappa"
        src={`https://maps.google.com/maps?q=${encodeURIComponent(
          address
        )}&t=&z=13&ie=UTF8&iwloc=&output=embed&hl=it`}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      ></iframe>
    </div>
  </div>
);

export default MapContainer;
